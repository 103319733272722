import { Link } from '@solidjs/router';
import { createResource, For, Match, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getProductChildrenQuery } from '../../graphql/queries/products';
import { Box } from '../layout/box/box';
import { Row } from '../layout/row/row';
import { Tags } from '../layout/tag/tags';
import { ProductInfoProps } from './product-types';
import { ConditionsOfUse } from './conditions-of-use/conditions-of-use';
import { AdditionalConditions } from './additional-conditions/additional-conditions';
import { ExpandableBox } from '../layout/expandable-box/expandable-box';
import { StyledLink } from '../strategies/strategies.styles';
import { ArrowOut } from '../svg/svg';
import { StyledRowsContainer } from './products.styles';

export const ProductInfo = (props: ProductInfoProps) => {
    const { graphQlClient } = useContext(AppContext);

    const [children] = createResource(props, async variables => {
        if (!variables.product.children) {
            return [];
        }

        const response = await graphQlClient(getProductChildrenQuery, { slug: variables.product.slug });
        return response?.children || [];
    });

    const bothChemicalInspectionTablesHaveData = () =>
        props.product.chemicalInspectionData?.useageTable?.rows?.length && props.product.chemicalInspectionData?.conditionsTable?.rows?.length;

    return (
        <Show
            when={!props.product?.children?.length}
            fallback={
                <Box>
                    <p>
                        <strong>Länkade produkter</strong>
                    </p>
                    <ul>
                        <For each={children()}>
                            {child => (
                                <li>
                                    <Link href={`/kategorier/produkter/${child.slug}`}>{child.name}</Link>
                                </li>
                            )}
                        </For>
                    </ul>
                </Box>
            }
        >
            <StyledRowsContainer>
                <Box>
                    <Row justifyContent="space-between">
                        <strong>{props.product.categories.length === 1 ? 'Kategori' : 'Kategorier'}</strong>
                        <span>{props.product.categories.map(category => category.name).join(', ')}</span>
                    </Row>
                </Box>

                <Show when={props.product.information}>
                    <Box>
                        <p>
                            <strong>Generell information</strong>
                        </p>
                        <div innerText={props.product.information} />
                    </Box>
                </Show>

                <Show when={!props.product.noRainproofing}>
                    <Box>
                        <p>
                            <strong>Regnfasthet</strong>
                        </p>
                        <Show when={props.product.rainproofingInfo}>
                            <div>Tid: {props.product.rainproofingInfo}</div>
                        </Show>
                        <div>Bladverkan: {props.product.affectsLeaves ? 'Ja' : 'Nej'}</div>
                        <div>Jordverkan: {props.product.affectsGround ? 'Ja' : 'Nej'}</div>
                    </Box>
                </Show>
            </StyledRowsContainer>

            <Switch>
                <Match when={bothChemicalInspectionTablesHaveData()}>
                    <ExpandableBox
                        expandableChildren={<AdditionalConditions tableData={props.product.chemicalInspectionData.conditionsTable} />}
                        showChildrenButtonText="Visa ytterligare vilkor"
                        hideChildrenButtonText="Göm ytterligare vilkor"
                    >
                        <ConditionsOfUse
                            pdfLink={props.product.chemicalInspectionData.pdfLink}
                            tableData={props.product.chemicalInspectionData.useageTable}
                        />
                    </ExpandableBox>
                </Match>
                <Match when={props.product.chemicalInspectionData?.useageTable?.rows?.length}>
                    <Box>
                        <ConditionsOfUse
                            pdfLink={props.product.chemicalInspectionData.pdfLink}
                            tableData={props.product.chemicalInspectionData.useageTable}
                        />
                    </Box>
                </Match>
                <Match when={props.product.chemicalInspectionData?.conditionsTable?.rows?.length}>
                    <Box>
                        <AdditionalConditions tableData={props.product.chemicalInspectionData.conditionsTable} />
                    </Box>
                </Match>
            </Switch>

            <StyledRowsContainer>
                <Show when={props.product.waitingDaysInformation && !props.product.chemicalInspectionData?.useageTable?.rows?.length}>
                    <Box>
                        <p>
                            <strong>Karens och användarvillkor</strong>
                        </p>
                        <div innerText={props.product.waitingDaysInformation} />
                    </Box>
                </Show>

                <Show when={props.product.activeIngredients.length}>
                    <Box>
                        <p>
                            <strong>Aktiva substanser</strong>
                        </p>
                        <For each={props.product.activeIngredients}>
                            {activeIngredient => (
                                <Row>
                                    <span style={{ width: '200px' }}>{activeIngredient.name}:</span>
                                    <span>
                                        {activeIngredient.amount} {activeIngredient.unit}
                                    </span>
                                </Row>
                            )}
                        </For>
                    </Box>
                </Show>

                <Show when={props.product.link || props.product.chemicalInspectionLink}>
                    <Box>
                        <p>
                            <strong>Länkar</strong>
                        </p>
                        <Show when={props.product.link}>
                            <Row>
                                <StyledLink href={props.product.link} target="_blank">
                                    Produkten hos tillverkaren
                                    <ArrowOut />
                                </StyledLink>
                            </Row>
                        </Show>
                        <Show when={props.product.chemicalInspectionLink}>
                            <Row>
                                <StyledLink href={props.product.chemicalInspectionLink} target="_blank">
                                    Säkerhetsdatablad
                                    <ArrowOut />
                                </StyledLink>
                            </Row>
                        </Show>
                    </Box>
                </Show>
            </StyledRowsContainer>
        </Show>
    );
};
