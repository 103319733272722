import { Accessor, For, Show, createSignal, createEffect, createMemo } from 'solid-js';
import { Select } from '../../select/select';
import {
    StyledTable,
    StyledLeftmostTableHeader,
    StyledLeftmostTableCell,
    StyledTableRow,
    StyledTableHeader,
    StyledTableCell,
    StyledConditionsOfUseTableContainer,
    StyledPlaceHolder,
    StyledConditionsOfUse,
    StyledConditionsOfUseTableHeader,
} from './conditions-of-use.styles';

import { formatRows } from './helpers';
import { ChemicalInspectionTableData } from '../product-types';
import { StyledLink } from '../../strategies/strategies.styles';
import { ArrowOut } from '../../svg/svg';

type ConditionsOfUseProps = {
    tableData: ChemicalInspectionTableData;
    pdfLink?: string;
};

export const ConditionsOfUse = (props: ConditionsOfUseProps) => {
    const [selectedSituations, setSelectedSituations] = createSignal<string[]>([]);

    const usageTableRows = () => formatRows(props.tableData.rows);

    const getSituationSelectOptions = createMemo(() => {
        const situations = props.tableData.rows.map((column: string[]) => column[0]);
        return situations.map((situation: string, index: number) => ({
            value: (index + 1).toString(), // adjusted to be 1-based
            text: situation,
        }));
    });

    createEffect(() => {
        if (getSituationSelectOptions().length === 1) {
            // If there's only one option, set it as selected
            setSelectedSituations([getSituationSelectOptions()[0].value]);
        }
    });

    const rowsWithSelectedColumns = () => {
        const headerAndRows = usageTableRows().map((row: string[], index: number) => {
            return [props.tableData.header[index], ...row];
        });

        const indicesToKeep = selectedSituations().map(index => parseInt(index, 10));
        indicesToKeep.unshift(0); // Always include the first column

        const selected = headerAndRows.map(row => indicesToKeep.map(index => row[index]));

        // Remove the final row (Ändring) which we don't need to show
        const columns = selected.slice(0, selected.length - 1);

        return columns.map(column => column.map(c => c.replace(/\s/g, ' ')));
    };

    return (
        <div>
            <StyledConditionsOfUseTableHeader>
                <p>
                    <strong>{props.tableData.title}</strong>
                </p>
                <Show when={props.pdfLink}>
                    <p>
                        Källa:{' '}
                        <StyledLink href={props.pdfLink as string} target="_blank">
                            Kemikalieinspektionen-PDF
                            <ArrowOut />
                        </StyledLink>
                    </p>
                </Show>
            </StyledConditionsOfUseTableHeader>
            <Select
                mode="multiple"
                value={selectedSituations()}
                options={getSituationSelectOptions()}
                placeholder="Välj gröda..."
                onChange={(situations: string[]) => {
                    setSelectedSituations(situations);
                }}
            />
            <StyledConditionsOfUse selectedSituationsAmount={selectedSituations().length}>
                <StyledConditionsOfUseTableContainer>
                    <StyledTable fullWidth={selectedSituations().length > 0}>
                        <thead>
                            <tr>
                                <For each={rowsWithSelectedColumns()[0]}>
                                    {(column: string, index: Accessor<number>) =>
                                        index() === 0 ? (
                                            <StyledLeftmostTableHeader>{column}</StyledLeftmostTableHeader>
                                        ) : (
                                            <StyledTableHeader selectedSituationsAmount={selectedSituations().length}>{column}</StyledTableHeader>
                                        )
                                    }
                                </For>
                            </tr>
                        </thead>
                        <tbody>
                            <For each={rowsWithSelectedColumns().slice(1)}>
                                {(row: string[]) => (
                                    <StyledTableRow>
                                        <For each={row}>
                                            {(column: string, index: Accessor<number>) =>
                                                index() === 0 ? (
                                                    <StyledLeftmostTableCell>{column}</StyledLeftmostTableCell>
                                                ) : (
                                                    <StyledTableCell selectedSituationsAmount={selectedSituations().length}>{column}</StyledTableCell>
                                                )
                                            }
                                        </For>
                                    </StyledTableRow>
                                )}
                            </For>
                        </tbody>
                    </StyledTable>
                    <Show when={selectedSituations().length < 1}>
                        <StyledPlaceHolder>
                            <h3>Välj gröda</h3>
                            <p>Välj en eller flera grödor ovanför för att se dess vilkor för användning.</p>
                        </StyledPlaceHolder>
                    </Show>
                </StyledConditionsOfUseTableContainer>
            </StyledConditionsOfUse>
        </div>
    );
};
