import { For } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { Link } from '../link/link';
import { Header } from '../layout/header/header';
import { Container } from '../layout/container/container';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import type { PurposesProps } from './purposes.d';
import { AdsContainer } from '../../containers/ads/ads-container';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';

export const Purposes = (props: PurposesProps) => {
    const category = () => props.cropTypeCategoryData()?.cropTypeCategory;
    const cropType = () => props.cropTypeData()?.cropType;
    const crop = () => props.cropData()?.crop;
    const purposes = () => props.purposesData()?.purposes;
    const strategies = () => props.strategiesData()?.strategies;
    const prompt = 'Välj behandlingsområde';

    const filteredPurposes = () => {
        if (!strategies()?.rows || !purposes()) {
            return [];
        }

        const strategyTreatments = new Set();
        strategies()?.rows?.forEach(({ treatments }) => {
            treatments.forEach(({ slug }) => strategyTreatments.add(slug));
        });

        const result = purposes()?.rows?.filter(({ treatments = [] }) => {
            return treatments.some(({ slug }) => strategyTreatments.has(slug));
        });
        return result;
    };

    const links = () => [
        {
            text: category()?.name,
            url: `/strategier/${category()?.slug}/`,
        },
        {
            text: cropType()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}`,
        },
        {
            text: crop()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}`,
        },
    ];

    const metaTitle = () => `${crop()?.name || ''} | ${cropType()?.name || ''} | ${category()?.name || ''} - Gullvikspraktikan`;

    return (
        <>
            <Title>{metaTitle()}</Title>
            <BreadCrumbs links={links()} prompt={`${prompt}...`} />
            <Header header={crop()?.name} />
            <Container header={prompt}>
                <For each={filteredPurposes()} fallback={<SkeletonLoader />}>
                    {(purpose) => (
                        <Link href={`/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}/${purpose.slug}`}>
                            {purpose.name}
                        </Link>
                    )}
                </For>
                <AdsContainer placement={Placements.Crop} slug={crop()?.slug} />
            </Container>
        </>
    );
};
