import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';

const handleBackgrounColor = (value = '') => {
    const num = parseInt(value);
    if (isNaN(num)) {
        return '#fff';
    }

    if (num < 40) {
        return '#fff';
    } else if (num >= 40 && num < 70) {
        return '#a6eaff';
    } else if (num >= 70 && num < 90) {
        return '#ffbd00';
    } else if (num >= 90) {
        return '#607f0b';
    }
};

const handleColor = (value = '') => {
    const num = parseInt(value);
    if (isNaN(num)) {
        return '#262626';
    }

    return num >= 90 ? '#fff' : '#262626';
};

export const StyledProductSkeletons = styled.div`
    div {
        margin-bottom: 1rem;
    }
`;

export const StyledEffectSchema = styled.div``;

export const StyledEffectSchemaTable = styled.div`
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

export const StyledProductContainer = styled.div`
    background: ${colors.white};
    padding: 1.5rem 0 0;
    color: #262626;
    height: calc(100% - 100px); /* 100% minus height of (StyledDialogHeader + little extra) */
    overflow-y: auto;
`;

export const StyledProductHeader = styled.div`
    button {
        height: 40px;
        width: 40px;
        padding: 0;
        left: 1rem;
    }

    svg {
        height: 30px;
        width: 30px;
    }
`;

export const StyledColumn = styled.div`
    border-radius: 4px;
    min-width: 42px;
    text-align: center;
    margin: 0.25rem;
`;

export const StyledEffectColumn = styled.div<{ effect: string }>`
    background: ${props => handleBackgrounColor(props.effect)};
    color: ${props => handleColor(props.effect)};
    border-radius: 4px;
    min-width: 42px;
    text-align: center;
    margin: 0.25rem;
`;

export const StyledEffectDoseInfo = styled.div`
    font-size: 14px;
    font-style: italic;
`;

export const StyledText = styled.div`
    line-height: 1.4;
`;

export const StyledRowsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    > div {
        width: calc(50% - 0.5rem);
    }
`;
