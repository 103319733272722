import { gql } from 'graphql-request';

export const getPurposeQuery = gql`
    query purpose($slug: String) {
        purpose: getPurpose(slug: $slug) {
            uuid
            slug
            name
            description
            treatments {
                uuid
                slug
                name
                combined
            }
        }
    }
`;

export const getPurposesQuery = gql`
    query purposes($searchTerm: String, $limit: Int, $offset: Int) {
        purposes: getPurposes(searchTerm: $searchTerm, limit: $limit, offset: $offset) {
            count
            rows {
                uuid
                slug
                name
                treatments {
                    uuid
                    slug
                    name
                    combined
                }
            }
        }
    }
`;
