import { useLocation, useParams } from '@solidjs/router';
import { useContext } from 'solid-js';
import { Strategies } from '../../components/strategies/strategies';
import { getCropQuery, getCropTypeCategoryQuery, getCropTypeQuery } from '../../graphql/queries/crops';
import { getProductsLiteByUuidsQuery } from '../../graphql/queries/products';
import { getPurposesQuery } from '../../graphql/queries/purpose';
import { Screen } from '../../components/layout/screen/screen';
import { getStrategiesQuery } from '../../graphql/queries/strategies';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Strategy, StrategyProduct } from '../../components/strategies/strategies.d';

export const StrategiesContainer = () => {
    const { createCachedResource } = useContext(AppContext);
    const { cropSlug, purposeSlug, categorySlug, cropTypeSlug } = useParams();

    const getSelectedTreatments = (): string[] => {
        const params = new URLSearchParams(useLocation().search);
        const selected = params.get('mal') || '';

        return selected.split(',');
    };

    const [cropTypeCategoryData] = createCachedResource(getCropTypeCategoryQuery, { slug: categorySlug });
    const [cropTypeData] = createCachedResource(getCropTypeQuery, { slug: cropTypeSlug });
    const [strategiesData] = createCachedResource(getStrategiesQuery, { cropSlug, treatmentSlugs: getSelectedTreatments() });
    const [cropData] = createCachedResource(getCropQuery, { slug: cropSlug });
    const [purposesData] = createCachedResource(getPurposesQuery, { limit: 100 });

    const strategiesProductsData = () => {
        const productUuids = strategiesData()?.strategies?.rows.flatMap((strategy: Strategy) => {
            return strategy?.products?.map((product: StrategyProduct) => product?.uuid);
        });

        const [productsData] = createCachedResource(getProductsLiteByUuidsQuery, { uuids: productUuids });
        return productsData;
    };

    return (
        <Screen>
            <Strategies
                purposeSlug={purposeSlug}
                cropData={cropData}
                strategiesData={strategiesData}
                purposesData={purposesData}
                cropTypeData={cropTypeData}
                selectedTreatments={getSelectedTreatments()}
                cropTypeCategoryData={cropTypeCategoryData}
                strategiesProductsData={strategiesProductsData}
            />
        </Screen>
    );
};
