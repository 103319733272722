import { gql } from 'graphql-request';

export const getStrategiesQuery = gql`
    query strategies($cropSlug: String, $treatmentSlugs: [String]!) {
        strategies: getStrategiesForFrontend(cropSlug: $cropSlug, treatmentSlugs: $treatmentSlugs) {
            count
            rows {
                createdAt
                updatedAt
                deletedAt
                information
                springAutumnTreatment
                growthPeriodStart
                growthPeriodEnd
                growthPeriodText
                doseInfoText
                paused
                doseArea
                crop {
                    slug
                    name
                }
                treatments {
                    slug
                    name
                    effect
                }
                products {
                    slug
                    name
                    amount
                    unit
                    uuid
                    doseInfoText
                    children {
                        uuid
                        name
                        order
                    }
                }
            }
        }
    }
`;

export const getStrategiesSearchQuery = gql`
    query frontendSearch($cropSlug: String, $treatmentSlug: String) {
        strategies: frontendSearch(cropSlug: $cropSlug, treatmentSlug: $treatmentSlug) {
            crop {
                slug
                name
            }
            treatments {
                slug
                name
            }
            products {
                slug
                name
                children {
                    slug
                    name
                }
            }
        }
    }
`;
