import { gql } from 'graphql-request';

export const getProductsQuery = gql`
    query products($searchTerm: String, $ingredientSearchTerm: String, $limit: Int, $offset: Int) {
        products: getProducts(searchTerm: $searchTerm, ingredientSearchTerm: $ingredientSearchTerm, limit: $limit, offset: $offset) {
            count
            rows {
                slug
                name
            }
        }
    }
`;

export const getProductsLiteByUuidsQuery = gql`
    query getProductsLiteByUuids($uuids: [String]) {
        products: getProductsLiteByUuids(uuids: $uuids) {
            products {
                uuid
                rank
                slug
                name
            }
        }
    }
`;

export const getMixableProductsQuery = gql`
    query mixableProducts {
        mixableProducts: getMixableProducts {
            slug
            name
        }
    }
`;

export const getUpmaProductsQuery = gql`
    query upmaProducts {
        upmaProducts: getUpmaProducts {
            slug
            name
        }
    }
`;

export const getProductsWithRegistrationNumberQuery = gql`
    query getProductsWithRegistrationNumber($registrationNumbers: [String!]!) {
        products: getProductsWithRegistrationNumber(registrationNumbers: $registrationNumbers) {
            registrationNumber
            slug
        }
    }
`;

export const getProductQuery = gql`
    query product($slug: String) {
        product: getProduct(slug: $slug) {
            slug
            name
            information
            rainproofingInfo
            affectsLeaves
            affectsGround
            noRainproofing
            link
            chemicalInspectionLink
            chemicalInspectionData
            waitingDaysInformation
            treatments {
                slug
                name
            }
            children {
                uuid
                name
            }
            categories {
                slug
                name
            }
            effects {
                dosageInformation
                crop {
                    slug
                    name
                }
                treatments {
                    slug
                    name
                    dose
                }
            }
            mixings {
                name
                status
                days
                notes
                slug
            }
            activeIngredients {
                name
                unit
                amount
            }
            registrationNumbers {
                isApproved
                registrationNumber
                upmas {
                    areaOfUse
                    decisionText
                    documentId
                    decisionId
                    approvedDate
                    approvedUntilDate
                    approvalCeasedDate
                    usageForbiddenDate
                }
            }
        }
    }
`;

export const getProductChildrenQuery = gql`
    query children($slug: String!) {
        children: getProductChildren(slug: $slug) {
            slug
            name
        }
    }
`;
