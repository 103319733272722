import { styled } from 'solid-styled-components';
import { colorsRgb } from '../../style/style-variables';

export const CheckboxComponent = styled.div<CheckboxComponentProps>`
    display: block;
    background: ${(p) => (p.highlighted ? '#FDE49B' : `rgba(${colorsRgb.yellow}, 0.2)`)};
    border-radius: 8px;
    margin-bottom: 0.5rem;
    font-size: 18px;
    display: flex;
    padding: 1rem 0.75rem;
    align-items: center;
    transition: opacity 0.25s ease;
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    opacity: ${(p) => (p.disabled ? '0.5' : 1)};

    input {
        margin-right: 0.75rem;
        transform: scale(1.4);
    }
`;
type CheckboxComponentProps = {
    disabled?: boolean;
    highlighted?: boolean;
}

export const StyledCheckboxLabel = styled.div<{ disabled?: boolean }>`
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
`;
