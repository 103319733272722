import { createSignal, For } from 'solid-js';
import { Box } from '../../layout/box/box';
import { StyledVerticalSpace } from '../../layout/layout.styles';
import { Select } from '../../select/select';
import { RegistrationNumber, Upma as UpmaType } from '../product-types';
import { Upma } from './upma';

type Props = {
    registrationNumbers: RegistrationNumber[];
};

export const Upmas = (props: Props) => {
    const [show, setShow] = createSignal('active');
    const upmas = () => {
        const filteredUpmas: UpmaType[] = [];

        props.registrationNumbers.forEach(({ upmas, isApproved, registrationNumber }) => {
            upmas.forEach((upma) => {
                filteredUpmas.push({
                    ...upma,
                    registrationNumber: {
                        isApproved,
                        registrationNumber,
                        product: {
                            slug: '',
                            name: '',
                        },
                    },
                });
            });
        });

        return filteredUpmas
            .filter(({ approvalCeasedDate }) => {
                if (show() === 'all') {
                    return true;
                }

                return !approvalCeasedDate;
            })
            .sort((a, b) => new Date(b.approvedDate || '').getTime() - new Date(a.approvedDate || '').getTime());
    };

    return (
        <>
            <Select
                mode="basic"
                value={show()}
                options={[
                    {
                        value: 'active',
                        text: 'Visa enbart gällande beslut',
                    },
                    {
                        value: 'all',
                        text: 'Visa alla',
                    },
                ]}
                onChange={setShow}
            />
            <StyledVerticalSpace size={1} />
            <For each={upmas()} fallback={<Box>Inga beslut hittades</Box>}>
                {upma => <Upma upma={upma} />}
            </For>
        </>
    );
};
