import { createSignal, Show } from 'solid-js';
import { format, differenceInDays } from 'date-fns';
import { Link } from '@solidjs/router';
import { Box } from '../../layout/box/box';
import { Row } from '../../layout/row/row';
import { Upma as UpmaType } from '../product-types';
import { StyledText } from '../products.styles';
import { colors } from '../../../style/style-variables';
import {
    StyledUpmaContent,
    StyledUPMAInstructionsForUse as StyledUpmaInstructionsForUse,
    StyledUPMAInformation as StyledUpmaInformation,
    StyledUpmaContentContainer,
    StyledUpmaProductTitle,
    StyledUpmaInformationContainer,
    StyledMoreInformationTrigger,
} from './upma.styles';
import { Toggle } from '../../layout/toggle/toggle';
import { StyledLink } from '../../strategies/strategies.styles';
import { ArrowOut } from '../../svg/svg';

type Props = {
    upma: UpmaType;
};

const parseDate = (date: string | null) => {
    if (!date) {
        return null;
    }

    return format(new Date(date), 'yyy-MM-dd');
};

const showExiprationWarning = (date: string | null) => {
    if (!date) {
        return false;
    }

    const diff = differenceInDays(new Date(date), Date.now());

    return diff > 0 && diff < 60;
};

export const Upma = (props: Props) => {
    const [isOpen, setIsOpen] = createSignal(false);

    return (
        <Box>
            <StyledUpmaContentContainer>
                <Show when={props.upma.registrationNumber}>
                    <StyledUpmaProductTitle>
                        <Show when={props.upma.registrationNumber?.product?.name}>
                            <strong>{props.upma.registrationNumber?.product?.name}</strong> -{' '}
                        </Show>
                        Regnr: {props.upma.registrationNumber?.registrationNumber}
                    </StyledUpmaProductTitle>
                </Show>
                <p>
                    <strong>
                        <Link
                            href={`https://apps.kemi.se/BkmRegistret/Kemi.Spider.Web.External/Beslut/Details?beslutId=${props.upma.decisionId}&objekttypId=7`}
                            target="_blank"
                        >
                            Beslut #{props.upma.decisionId}
                        </Link>
                    </strong>
                </p>
                <StyledUpmaContent>
                    <StyledUpmaInstructionsForUse>
                        <strong>Användning</strong>
                        <StyledText innerText={props.upma.areaOfUse || ''} />
                    </StyledUpmaInstructionsForUse>

                    <StyledUpmaInformation>
                        <Show when={showExiprationWarning(props.upma.approvedUntilDate)}>
                            <p>
                                <strong style={{ color: colors.red }}>OBS! Godkännandet upphör {parseDate(props.upma.approvedUntilDate)}</strong>
                            </p>
                        </Show>

                        <Row justifyContent="space-between">
                            <strong>Godkänt</strong>
                            <span>{parseDate(props.upma.approvedDate)}</span>
                        </Row>
                        <Show when={props.upma.approvalCeasedDate}>
                            <Row justifyContent="space-between">
                                <strong>Godkännandet upphörde</strong>
                                <span>{parseDate(props.upma.approvalCeasedDate)}</span>
                            </Row>
                        </Show>
                        <Show when={props.upma.usageForbiddenDate}>
                            <Row justifyContent="space-between">
                                <strong>Användningsförbud fr.o.m</strong>
                                <span>{parseDate(props.upma.usageForbiddenDate)}</span>
                            </Row>
                        </Show>

                        <Show when={props.upma.documentId || props.upma.decisionText}>
                            <Show
                                when={props.upma.documentId}
                                fallback={
                                    <>
                                        <StyledMoreInformationTrigger>
                                            <span onClick={() => setIsOpen(!isOpen())}>Ytterligare villkor</span>
                                            <Toggle isOpen={isOpen()} onClick={() => setIsOpen(!isOpen())} />
                                        </StyledMoreInformationTrigger>
                                        <StyledUpmaInformationContainer classList={{ 'is-visible': isOpen() }}>
                                            <StyledText innerText={props.upma.decisionText || ''} />
                                        </StyledUpmaInformationContainer>
                                    </>
                                }
                            >
                                <Row justifyContent="space-between">
                                    <strong>Ytterligare villkor</strong>
                                    <StyledLink
                                        target="_blank"
                                        href={`https://apps.kemi.se/BkmRegistret/Kemi.Spider.Web.External/File/GetDokument/${props.upma.documentId}`}
                                    >
                                        Visa PDF
                                        <ArrowOut />
                                    </StyledLink>
                                </Row>
                            </Show>
                        </Show>
                    </StyledUpmaInformation>
                </StyledUpmaContent>
            </StyledUpmaContentContainer>
        </Box>
    );
};
