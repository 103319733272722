import { styled } from 'solid-styled-components';

export const StyledTreatmentWrapper = styled.div`
    position: relative;
    height: 100%;
`;

export const StyledTreatmentHeader = styled.div`
    position: relative;
    padding: 2rem 0 1rem;

    h2 {
        margin: 0;
        text-align: center;
    }
`;

export const StyledToggleButton = styled.button`
    position: absolute;
    bottom: 1rem;
    right: 0;
    border: none;
    background: #a4343a;
    color: #ffffff;
    cursor: default;
    opacity: 1;
    font-size: 14px;
    padding: 0.5rem;
    border-radius: 8px;
    opactity: 1;
    transition: opacity 100ms;

    &:disabled {
        opacity: 0;
    }
`;

export const StyledButtonWrapper = styled.div`
    text-align: center;
    position: sticky;
    bottom: 1rem;

    @media (max-width: 768px) {
        left: 1rem;
        right: 1rem;
    }

    @media (min-width: 769px) {
        width: 50%;
        margin: 0 25%;
    }

    button {
        font-weight: 700;
        font-size: 18px;
        padding: 1rem;
        text-transform: uppercase;
        width: 100%;
        border-radius: 8px;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 20%);
    }
`;

export const StyledCombinedTreatmentWrapper = styled.div`
    margin-bottom: 2rem;
`;