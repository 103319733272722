import { StyledEffectMap, StyledEffectColorMapHeadline, StyledEffectMapSpan } from './strategies.styles';

export const EffectColorMap = () => (
    <div class="effect-color-map">
        <StyledEffectColorMapHeadline>Effekten visas med färgkartan:</StyledEffectColorMapHeadline>
        <StyledEffectMap>
            <StyledEffectMapSpan color={'green'}>{'>'}90 %</StyledEffectMapSpan>
            <StyledEffectMapSpan color={'yellow'}>70-89 %</StyledEffectMapSpan>
            <StyledEffectMapSpan color={'lightblue'}>40-69 %</StyledEffectMapSpan>
            <StyledEffectMapSpan color={'lightgray'}>{'<'}39 %</StyledEffectMapSpan>
        </StyledEffectMap>
    </div>
);
