import { styled } from 'solid-styled-components';
import { colorsRgb, colors } from '../../../style/style-variables';

export const StyledExpandableBox = styled.div`
    background: rgba(${colorsRgb.yellow}, 0.2);
    border-radius: 6px;
    color: #262626;
    border: 1px solid #f5e5b8;
    margin-bottom: 1rem;
    line-height: 1.8;
`;

export const StyledShowChildrenButton = styled.button`
    width: 100%;
    background-color: #fde49b;
    border-radius: 0 0 6px 6px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    padding: 16px 0;
    margin-bottom: 1.5rem;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: ${colors.red};
    border: none;
`;

export const StyledExpandableBoxMain = styled.div`
    padding: 1rem;
`;
