import { styled } from 'solid-styled-components';
import { colorsRgb } from '../../../style/style-variables';

export const StyledBox = styled.div`
    background: rgba(${colorsRgb.yellow}, 0.2);
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #f5e5b8;
    color: #262626;
    margin-bottom: 1rem;
    line-height: 1.8;
`;
